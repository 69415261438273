html,body{
    margin:0;
    box-sizing: border-box;
    background-color: #696969;

}
#app{
    margin-top:0;
    font-family: "Roboto", sans-serif;
    min-height:100vh;
    max-height: 100%;
    width:100vw;

}


#selector-container{
    display:grid;
    grid-template-columns: min(calc(50% - 1rem),30vw) min(calc(50% - 1rem), 30vw);
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    padding: 0 100px 0;
    /* background-color: #525252;
     */
     background-color: #777;
     
    
}

#selector-container > * {
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 400;
    color:#b53135;
    user-select: none;
    cursor:pointer;

}

@media only screen and (max-width: 768px) {
    #selector-container{
        display:grid;
        grid-template-columns: min(calc(50% - 1rem),30vw) min(calc(50% - 1rem), 30vw);
        gap: 1.5rem;
        padding: 0;

    }

}



#selector-container > *.active{
    border-bottom: 2px #31b5b1 solid;
    padding: 1rem 0;
    /* margin: 0 1rem; */
}


#header{
    text-align: center;
    background-color: #b53135;
    width:100vw;
    height:fit-content;
    margin: 0 auto;
    justify-content: center;
}

#header > img {
    margin:2rem 0;
    border: 3px solid white;
    border-radius: 50%;
    width:200px;
    height:200px;
    max-width: 100%;


}

#header > h4{
    font-weight: 400;
    font-size: 2.3rem;
    line-height: 100%;
    margin: 0 0 1rem 0;
}
#header > h5{
    font-weight: 400;
    font-size: 1.65rem;
    line-height: 100%;
    margin: 0;
    padding-bottom: 2rem;
}