#container{
    padding:1vh 5vh;
    
    

}


#container-grid{
    display:grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: min(calc(50% - 1rem),30vw) min(calc(50% - 1rem), 30vw);
    grid-template-rows: 1fr;
    gap: 1.5rem;
    
}

#container-header{
    padding-left: 10vw;
}

@media only screen and (max-width: 768px) {
    #container-grid{
        display:grid;
        grid-template-columns: calc(100% - 1rem);
        gap: 1.5rem;
    }

    #container-header{
        padding-left: 0;
    }

}



