:root{
    --primary-color: blue;
}

.card{
    display:flex;
    flex-direction: column;
    line-height: 1rem;
    height:100%;
}

.card:hover{
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}

.card-title{
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding:1.5rem 1.5rem 0;

}

.card-body{
    padding:1.25rem 1.5rem 1.5rem;
    height:100%;

}

.card .card-actions:last-child{
    border-radius: 0 0 2px 2px;
}

.card .card-actions{
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 1rem 1.25rem;
    font-size: .85rem;
    line-height: 1rem;
    min-height: .85rem;


}

.card .card-link{
    text-decoration: none;
    color:var(--primary-color);
    margin:0;
    padding:0;
    

}

.card .card-link:hover{
    cursor:pointer;
}

